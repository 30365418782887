<template>
    <v-layout>
        <v-flex xs2>
            <v-btn
                fab
                dark

                @click="openComment"
            >
                <v-icon
                    dark
                >
                    comment
                </v-icon>
            </v-btn>
        </v-flex>
        <v-flex xs2 md3 offset-xs6 offset-md9>
            <v-btn
                dark
                fab
                :disabled="valid"
                :color="clickedButton === 'Down' ? 'error' : ''"
                @click="decrementSingle()"
            >
                <icon
                    name="fa/regular/thumbs-down"
                    class="barIcon"
                    color="white"
                />
            </v-btn>
        </v-flex>
        <v-flex xs2>
            <v-btn
                dark
                fab
                :disabled="valid"
                :color="clickedButton === 'Up' ? 'success' : ''"
                @click="incrementSingle()"
            >
                <icon
                    name="fa/regular/thumbs-up"
                    class="barIcon"
                    color="white"
                />
            </v-btn>
        </v-flex>
    </v-layout>
</template>

<script>
import Icon, { register as registerIcons } from '@wisol/icons'
import thumbsUpIcon from '@wisol/icons/src/icons/fa/regular/thumbs-up'
import thumbsDownIcon from '@wisol/icons/src/icons/fa/regular/thumbs-down'
registerIcons(thumbsUpIcon, thumbsDownIcon)

export default {
    name: 'thumbInput',
    components: {
        Icon
    },
    data () {
        return {
            clickedButton: ''
        }
    },
    props: {
        singleCount: {
            type: Number,
            default () {
                return 0
            }
        },
        valid: {
            type: Boolean,
            default () {
                return false
            }
        }
    },
    created () {
        if (this.singleCount === -1) {
            this.clickedButton = 'Down'
        } else if (this.singleCount === 1) {
            this.clickedButton = 'Up'
        }
    },
    methods: {
        openComment () {
            this.$emit('openComment')
        },
        unselectButtons () {
            this.clickedButton = ''
            this.$emit('incrementSingle', {
                count: Number(0)
            })
        },
        incrementSingle () {
            if (this.clickedButton === 'Up') {
                this.unselectButtons()
            } else {
                this.clickedButton = 'Up'
                this.$emit('incrementSingle', {
                    count: Number(1)
                })
            }
        },
        decrementSingle () {
            if (this.clickedButton === 'Down') {
                this.unselectButtons()
            } else {
                this.clickedButton = 'Down'
                this.$emit('decrementSingle', {
                    count: Number(-1)
                })
            }
        }
    }
}
</script>
